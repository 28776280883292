<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Consider the crystal structure of elemental copper, which has a face-centered cubic lattice
        with
        <chemical-latex content="Cu" />
        atoms located at
        <stemble-latex content="$0,0,0.$" />
        If the density of
        <chemical-latex content="Cu" />
        is
        <stemble-latex content="$8.93\,\text{g/cm}^3,$" />
        what is the length
        <stemble-latex content="$a$" />
        of a unit cell edge (in Å)? What is the radius
        <stemble-latex content="$r$" />
        of a copper atom? Detail the steps of your calculation.
      </p>

      <p class="mb-5">
        <v-img style="max-width: 250px" src="/img/assignments/UofA/q2.png" />
      </p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.input1" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUofA103SandboxQ2',
  components: {
    STextarea,
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
